<template>
  <div :class="noteClass" ref="noteContainer">
    <p :class="pClass" v-for="(note, i) in noteArr" :key="note + i">
      {{ note }}
    </p>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "ScrollableText",
  data: () => ({
    noteClass: "",
    pClass: "",
    styleTimeout: 0,
  }),
  props: {
    noteArr: {
      type: Array,
      default: () => [],
    },
    scrollClasses: {
      type: String,
      default: "",
    },
    lineClasses: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.noteClass = this.scrollClasses + " scroll-multiline";
    this.pClass = this.lineClasses + " mb-0";

    this.styleTimeout = setTimeout(() => {
      this.computeNoteClass();
    }, 25);
  },
  methods: {
    computeNoteClass() {
      // using a timeout instead of computed because refs are not reactive
      if (
        this.$refs.noteContainer !== undefined &&
        this.$refs.noteContainer !== null
      ) {
        const container = this.$refs.noteContainer;
        if (container.scrollHeight > container.offsetHeight) {
          this.noteClass = this.noteClass + " scroll-triggered";
        }
      }
    },
  },
};
</script>
