<template>
  <page-main
    title="Your Submitted Resumes"
    :aside="[
      'Check on the review status of your submitted resume, or review feedback on a past resume.',
      'You can also submit an outside resume for review.',
    ]"
    noBottom
    :error="error"
  >
    <template v-slot:nav>
      <div class="pt-2 pt-sm-4">
        <v-system-bar color="accent" height="5"></v-system-bar>
        <p class="text-h6 mb-0 pt-2 pt-sm-4">Submit a New Resume</p>
        <p class="text-body-2 text--secondary font-italic mb-2">
          Want to submit a resume for review?
        </p>
        <v-btn outlined block @click="nav('/SubmitNewResume')"
          >Submit New Resume</v-btn
        >
      </div>
    </template>
    <v-container>
      <v-overlay color="primary" :absolute="true" v-if="loading">
        <v-progress-circular
          indeterminate
          color="accent"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-expansion-panels v-model="panel" multiple>
            <r-expansion-panel
              v-for="resume in localResumes"
              :key="resume.title"
            >
              <r-expansion-panel-header>
                <div class="d-flex align-center justify-space-between">
                  <div class="text-body-1">{{ resume.title }}</div>
                  <div class="text-body-2 pr-2">
                    {{ getLength(resume.reviews) }}
                  </div>
                </div>
              </r-expansion-panel-header>
              <r-expansion-panel-content>
                <v-container>
                  <v-row
                    class="pt-4 pb-2"
                    v-for="r in resume.reviews"
                    :key="r.id"
                  >
                    <v-col cols="12" class="r-col">
                      <resume-submission
                        :id="r.id"
                        :name="r.name"
                        :note="r.note"
                        :subLines="buildSublines(r)"
                        :status="r.status"
                        :imgHref="r.previewRef"
                        :fileHref="r.fileRef"
                        :filename="r.fileName"
                        @updateData="loadData()"
                        @error="setError($event)"
                      >
                      </resume-submission>
                    </v-col>
                  </v-row>
                </v-container>
              </r-expansion-panel-content>
            </r-expansion-panel>
          </r-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import * as disp from "../../utility/display";
import * as dutil from "../../utility/dateutil";
import ResumeSubmission from "../../components/ResumeSubmissionLine.vue";

// @ is an alias to /src
export default {
  name: "SubmittedResumes",
  data: () => ({
    panel: [],
    localResumes: [
      {
        title: "Active Resumes",
        reviews: [],
      },
      {
        title: "Reviewed Resumes",
        reviews: [],
      },
      {
        title: "Withdrawn Resumes",
        reviews: [],
      },
    ],
    loading: true,
    error: "",
  }),
  components: {
    "resume-submission": ResumeSubmission,
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    ...mapActions("userSubmissions", ["loadResumeSubmissions"]),
    async loadData() {
      try {
        this.loading = true;
        await this.loadResumeSubmissions();
      } finally {
        this.loading = false;
      }

      this.openPanels();
    },
    getLength(resumes) {
      if (resumes != null) {
        return `(${resumes.length})`;
      }
      return "(-)";
    },
    openPanels() {
      this.panel = [];
      if (this.localResumes[0].reviews.length > 0) {
        this.panel.push(0);
      }
      if (this.localResumes[1].reviews.length > 0) {
        this.panel.push(1);
      }
      if (this.localResumes[2].reviews.length > 0) {
        this.panel.push(2);
      }
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    updateReviews() {
      this.localResumes[0].reviews = [];
      this.localResumes[1].reviews = [];
      this.localResumes[2].reviews = [];
      this.resumes.forEach((r) => {
        if (r.status === "Submitted") {
          this.localResumes[0].reviews.push(r);
        } else if (r.status === "Reviewed") {
          this.localResumes[1].reviews.push(r);
        } else {
          this.localResumes[2].reviews.push(r);
        }
      });
    },
    buildSublines(r) {
      let lines = [];

      if (!disp.IsNullorWhitespace(r.dateSubmitted)) {
        lines.push(`Updated: ${dutil.dateStringToFormat(r.dateUpdated)}`);
      }
      if (!disp.IsNullorWhitespace(r.dateSubmitted)) {
        lines.push(`Submitted: ${dutil.dateStringToFormat(r.dateSubmitted)}`);
      }
      if (!disp.IsNullorWhitespace(r.fileName)) {
        lines.push(`${r.fileName}`);
      }

      return lines;
    },
    setError(error) {
      this.error = error;
    },
  },
  computed: {
    ...mapFields("userSubmissions", ["resumes"]),
  },
  watch: {
    resumes: {
      handler() {
        this.updateReviews();
        this.openPanels();
      },
    },
  },
};
</script>


<style scoped>
.resume-img {
  background-color: var(--v-resumeBackground-base);
  z-index: 1;
}

.button-holder {
  min-width: 140px;
}
</style>