<template>
  <div class="d-flex justify-space-between align-start">
    <div class="wide">
      <p class="text-body-1 mb-1">{{ name }}</p>
      <scrollable-text
        :noteArr="noteArr"
        lineClasses="text-body2"
        scrollClasses="mb-1"
      ></scrollable-text>
      <p
        v-for="(line, i) in subLines"
        :key="i"
        class="text-body-2 text--secondary mb-0"
      >
        {{ line }}
      </p>
    </div>
    <div class="d-flex pl-2">
      <div
        v-if="$vuetify.breakpoint.smAndUp"
        class="d-flex justify-end align-start"
      >
        <div class="pr-3">
          <img
            aspect-ratio="1"
            width="130"
            max-width="130"
            :src="imgHref"
            contain
            class="pa-1 resume-img"
          />
        </div>
      </div>
      <div class="button-holder">
        <v-btn v-if="reviewed" outlined block @click="handleView()">
          View Review
        </v-btn>
        <v-btn v-else outlined block @click="handleView()">
          View Submission
        </v-btn>
        <v-btn
          class="mt-2"
          v-if="!reviewed && !withdrawn"
          outlined
          block
          @click="handleWithdraw()"
        >
          Withdraw
        </v-btn>
        <v-btn v-if="withdrawn" disabled block class="mt-2"> Withdrawn </v-btn>
        <v-btn
          v-if="withdrawn"
          block
          outlined
          class="mt-2"
          @click="handleResubmit()"
        >
          Resubmit
        </v-btn>
        <v-btn block outlined class="mt-2" @click="handleDownload()">
          Download File
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import * as log from "../utility/logging/logger";
import * as careerApi from "../api/CareerApiUser";
import * as disp from "../utility/display.js";
import { mapMutations, mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import ScrollableText from "./ScrollableText.vue";

// @ is an alias to /src
export default {
  name: "ResumeSubmissionLine",
  data: () => ({}),
  components: {
    "scrollable-text": ScrollableText,
  },
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    note: {
      type: String,
      default: "Select a Resume Format",
    },
    subLines: {
      type: Array,
      default: () => [],
    },
    status: {
      type: String,
      default: "",
    },
    imgHref: {
      type: String,
      default: "",
    },
    fileHref: {
      type: String,
      default: "",
    },
    filename: {
      type: String,
    },
  },
  mounted() {},
  computed: {
    withdrawn() {
      return this.status === "Withdrawn";
    },
    submitted() {
      return this.status === "Submitted";
    },
    reviewed() {
      return this.status === "Reviewed";
    },
    ...mapFields("currentReview", ["loadResumeError"]),
    noteArr() {
      if (this.note == null || this.note == "") {
        return [];
      }
      return this.note.split("\n");
    },
  },
  methods: {
    ...mapMutations("userSubmissions", ["setCurrentResume"]),
    ...mapActions("currentReview", ["loadReviewUser"]),
    async handleView() {
      await this.loadReviewUser({ reviewId: this.id });
      if (!disp.IsNullorWhitespace(this.loadResumeError)) {
        this.$emit(
          "error",
          "Unable to load your resume review. Please try again."
        );
      } else {
        this.nav(`/ResumeReview/${this.id}`);
      }
    },
    async handleWithdraw() {
      try {
        var result = await careerApi.WithdrawReview(this.id);
        if (!result.success) {
          log.logError(
            `Unable to withdraw resume ${this.id}`,
            "ResumeSubmission.handleWithdraw"
          );
          this.$emit(
            "error",
            "Unable to withdraw your resume. Please try again."
          );
        }
        this.$emit("updateData");
      } catch (er) {
        log.logError(
          `Unhandled error - Unable to withdraw resume ${this.id}`,
          "ResumeSubmission.handleWithdraw"
        );
        this.$emit(
          "error",
          "Unable to withdraw your resume. Please try again."
        );
      }
    },
    async handleResubmit() {
      try {
        var result = await careerApi.ReactivateReview(this.id);
        if (!result.success) {
          log.logError(
            `Unable to resubmit resume ${this.id}`,
            "ResumeSubmission.handleResubmit"
          );
          this.$emit(
            "error",
            "Unable to resubmit your resume. Please try again."
          );
          return;
        }
        this.$emit("updateData");
      } catch (er) {
        log.logError(
          `Unhandled error - Unable to resubmit resume ${this.id}`,
          "ResumeSubmission.handleResubmit"
        );
        this.$emit(
          "error",
          "Unable to resubmit your resume. Please try again."
        );
      }
    },
    async handleDownload() {
      this.downloadFileFromUrl(this.fileHref);
    },
    downloadFileFromUrl(fileUrl) {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.target = "_blank";
      document.body.append(link);
      link.click();
      link.remove();
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
  },
};
</script>

<style scoped>
.resume-img {
  background-color: var(--v-resumeBackground-base);
  z-index: 1;
}
</style>